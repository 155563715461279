import React from 'react';

import BlueprintLayout from '../components/ui/Layout/BlueprintLayout';
import { BLUEPRINT_PORT_MENU } from '../utils/constants';

import BlueprintPortTemplate from '../templates/BlueprintPortTemplate';

const BlueprintPort = () => {
  return (
    <BlueprintLayout menuItems={BLUEPRINT_PORT_MENU}>
      <BlueprintPortTemplate />
    </BlueprintLayout>
  );
};

export default BlueprintPort;
