import React from 'react';
import classnames from 'classnames';

import DBProgramsByRegion from '../images/charts/chart_database-programs-by-region_portuguese.png';
import DBProgramsByType from '../images/charts/chart_database-programs-by-type_portuguese.png';
import INNPartnerByRegion from '../images/charts/chart_deep-dive-programs-by-region_portuguese.png';
import INNPartnerByType from '../images/charts/chart_deep-dive-programs-by-type_portuguese.png';

const BlueprintPortTemplate = (resources: any) => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <article className={'module-featured'}>
          <h1>
            Plataforma global da Incarceration Nations Network (Rede de Nações
            do Encarceramento):
          </h1>
          <p>Observações e reflexões</p>
          <figure>
            <img
              src="https://www.berlin.de/converjon/?ts=1418132797&width=540&height=270&url=https%3A%2F%2Fwww.berlin.de%2Fjustizvollzug%2F_assets%2Fjva-heidering%2Fdie-anstalt%2Fpforte.jpg"
              alt="Placeholder"
              width="1440"
              height="710"
            />
          </figure>
        </article>
        <article className={'s16'} id={'notes'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              O encarceramento em massa é uma calamidade global.
            </h2>
          </header>
          <div className={'triple'}>
            <div>
              <p>
                Há cerca de 11 milhões de pessoas atrás das grades no mundo
                hoje, número que aumenta com constância. Entre 2008 e 2011, a
                população carcerária aumentou 78 porcento em todos os países,
                entre 2000 e 2016 houve um aumento de cerca de 20 porcento.
                Entre 2000 e 2015, a população carcerária em toda a Oceania
                aumentou 60 porcento; nas Américas o crescimento foi de 40
                porcento ao todo (14 porcento nos EUA; mais de 80 porcento nos
                países da América Central; 145 porcento nos países da América do
                Sul). O número de presos sentenciados à prisão perpétua chegou
                quase a dobrar desde 2000.
              </p>
            </div>
            <div>
              <p>
                Mais de 3,2 dos 11 milhões de pessoas encarceradas não foram
                julgadas – são legalmente inocentes à espera de julgamento. Uma
                em cada cinco destas 11 milhões de pessoas atrás das grades foi
                presa por delitos relativos a drogas, 83 porcento pela simples
                posse de drogas. Um relatório da{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://justice.sdg16.plus/report"
                >
                  organização Task Force on Justice
                </a>{' '}
                calcula que 4,4 bilhões de pessoas não têm qualquer
                possibilidade de se beneficiar das leis e 244 milhões estão em
                condições de extrema injustiça.
              </p>
            </div>

            <div>
              <p>
                pesar do termo encarceramento em massa, a questão não afeta as
                massas de maneira uniforme. Comunidades marginais —
                afro-americanos e latinos nos EUA; povos nativos no Canadá,
                Austrália e Nova Zelândia; ciganos em toda a Europa; pobres em
                todo o Hemisfério Sul— são alvejadas e enredadas pelos sistemas
                criminais de justiça em índices dramaticamente desproporcionais,
                como consequência de matrizes históricas e realidades atuais
                enraizadas nas normas do capitalismo.
              </p>
              <hr />
            </div>
            <div>
              <figure>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </figure>
            </div>
            <div>
              <p>
                Não se trata de uma crise de direitos humanos apenas. Trata-se
                de uma profunda ameaça à paz e à segurança, dado que as prisões
                — um método ultrapassado e retrógrado de garantir a segurança
                pública e reduzir o crime — não contribuem para comunidades mais
                seguras nem colaboram para instaurar paz e justiça. Uma vasta
                quantidade de dados comprova isso. Um relatório de{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://penphilanthropy.org/files/Focus_Areas/Criminal_Justice_Reform/The_impacts_of_incarceration_on_crime_10.pdf"
                >
                  2017 da organização Open Philanthropy Project
                </a>{' '}
                avaliou 35 estudos internacionais e concluiu que sentenças mais
                severas “não impedem o crime” e que o tempo passado em prisões
                “tende a aumentar a criminalidade [do indivíduo] depois de
                solto” —ou seja, prisões geram o crime em lugar de impedi-lo. Um
                estudo de{' '}
                <a
                  href="https://Nature.com/articles/s41562-019-0604-8.epdf"
                  style={{ color: 'black' }}
                >
                  {' '}
                  2019 conduzido pela Universidade da Califórnia em Berkeley
                </a>{' '}
                demonstrou que sentenças de prisão não impedem as pessoas de
                cometerem crimes violentos e que mantê-las atrás das grades tem
                efeito quase nulo na redução de crimes violentos em geral.
              </p>
              <p>
                A Incarceration Nations Network (Rede de Nações Encarceradas) é
                uma rede global e um laboratório de ideias que apoia, incentiva
                e divulga iniciativas de reimaginar as prisões e a justiça em
                todo o mundo.
              </p>
            </div>
            <div>
              <figure className={'m35'}>
                <img
                  src="https://static.wixstatic.com/media/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.webp"
                  alt="Placeholder"
                  width="400"
                  height="175"
                />
              </figure>
              <p>
                A plataforma multimídias da INN — até onde sabemos, a primeira
                do gênero — também é fruto desta crise global, refletindo nossa
                paixão por soluções, sejam em grande, sejam em pequena escala.
                Apresentando novas visões de sistemas prisionais e esforços de
                reformulação em todo o mundo, ela consiste em uma ampla base de
                dados que reúne 800 programas globais e uma exploração
                aprofundada de 82 programas entre os mais promissores, sendo as
                atividades e os direcionamentos agrupados em categorias.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'premises'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>PREMISSAS DA PLATAFORMA INN:</h2>
            <p style={{ color: '#7D7D7D' }}>
              A plataforma INN parte de três premissas a priori sobre justiça
            </p>
          </header>
          <div className={classnames('double', 'a')}>
            <div className={'m0'}>
              <ul>
                <li>
                  <p>
                    Modelos de justiça que não se baseiam em prisões mas em
                    justiça restaurativa e outras abordagens alternativas são
                    mais humanitários e eficazes para a construção de
                    comunidades justas e seguras do que prisões e detenções.
                    Estes não deveriam ser “alternativas ao encarceramento” mas
                    o principal exercício da justiça. Esta deve ser a base para
                    nossas questões a respeito de justiça. Em lugar de
                    perguntarmos “quais programas de reabilitação funcionam?”
                    deveríamos questionar para além das prisões: “O que cria
                    comunidades seguras? Quais as necessidades daqueles que
                    foram feridos e de que forma podemos atendê-las?”
                  </p>
                </li>
                <li>
                  <p>
                    Ainda que mudanças sistêmicas radicais e visões de longo
                    alcance sejam muito necessárias para ampliar a justiça no
                    mundo, há muito espaço também para reformulações e
                    iniciativas graduais — uma vez que são milhões de vidas em
                    jogo aqui e agora. Reduzir o número de encarcerados e
                    melhorar as condições de vida nas prisões são vitais para os
                    direitos humanos e para a segurança das comunidades. É
                    portanto possível, ainda que paradoxal, colaborar para
                    reformulações que salvem vidas e ao mesmo tempo procurar
                    implantar modelos que eliminem as prisões e tornem tais
                    reformulações desnecessárias.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    Quando se trata de comunidades seguras, nada é melhor do que
                    prevenção, o que se traduz em uma sociedade baseada na
                    igualdade, em oportunidades econômicas para todos e em
                    serviços sociais consistentes. Conforme demonstra o
                    movimento de reinvestimento em justiça, a verdadeira justiça
                    não opera no vácuo, tendo alcance amplo e holístico para
                    transformer todos os aspectos da sociedade em nome da paz.
                  </p>
                </li>
              </ul>
              <figure>
                <img
                  src="https://www.score.gov.sg/images/default-source/default-album/chef-working-in-kitchen.jpg"
                  alt="Placeholder"
                  width="785"
                  height="280"
                />
              </figure>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'goals'}>
          <figure className={'background'}>
            <img
              src="https://mediaprocessor.websimages.com/www.corkalliancecentre.com/patricks%20hill%20view.jpg"
              alt="Placeholder"
              width="1440"
              height="800"
            />
          </figure>
          <header className={'text-center'}>
            <h2>OBJETIVOS DA PLATAFORMA INN</h2>
            <p>A INN tem objetivos variados para sua plataforma multimídias:</p>
          </header>
          <div className={'triple'}>
            <div>
              <ul>
                <li>
                  Criar um mapa de soluções promissoras para a crise mundial de
                  encarceramento.
                </li>
                <li>
                  Demonstrar que conversas sobre a reformulação de prisões e sua
                  reimaginação podem acontecer globalmente e através de
                  fronteiras, ainda que reconhecendo as limitações de aplicar
                  generalizações a diferentes países.
                </li>
                <li>
                  Formar uma coalisão que ultrapasse fronteiras e apoiar
                  organizações que façam trabalhos vitais pela justiça, em
                  especial organizações que trabalhem e atendam a populações
                  encarceradas em ambientes públicos hostis e punitivos.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  Construir uma ampla base de dados de modo a permitir que
                  organizações globais que trabalhem com a reformulação
                  prisional e acadêmicos/pesquisadores/inovadores em todo o
                  mundo estejam literalmente na mesma página.
                </li>
                <li>
                  Incentivar uma rede global de pessoas dedicadas a mudanças que
                  permita a reprodução de programas bem-sucedidos ao redor do
                  mundo.
                </li>
                <li>
                  Provocar diálogos através de fronteiras a respeito de
                  problemas em comum e de resultados bem-sucedidos.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  Combinar dados e imaginação de modo a atrair novos olhares
                  para a crise mundial de encarceramento em massa, em especial
                  agentes vitais para este trabalho: novos financiadores, o
                  público eleitor e a mídia.
                </li>
                <li>
                  Prover recursos práticos para organizações em países com
                  prisioneiros estrangeiros que possam se beneficiar das
                  conexões com organizações internacionais.
                </li>
              </ul>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'audience'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>PÚBLICO DA PLATFORMA INN</h2>
            <p style={{ color: '#7D7D7D' }}>
              O público da plataforma global da INN é amplo
            </p>
          </header>
          <div className={'module-aside'}>
            <figure>
              <img
                style={{ maxHeight: '330px', objectFit: 'cover' }}
                src="https://www.crcnsw.org.au/wp-content/uploads/2016/10/highlight2.jpg"
                alt="Placeholder"
                width="865"
                height="330"
              />
            </figure>
            <div>
              <p>
                Agências governamentais e ministérios; ONGs que trabalhem com
                questões judiciárias; veículos de comunicação, pesquisadores
                acadêmicos e estudantes de direito e áreas afins; financiadores
                em potencial do setor corporativo; filantropos e outros
                financiadores; ativistas e cidadãos participantes; detentos e/ou
                egressos “não assumidos”, que lutem contra o preconceito e a
                exclusão social; familiares dos encarcerados.
              </p>
            </div>
          </div>
        </article>
        <article className={'mn'} id={'methodology'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              METODOLOGIA DE COLETA DE DADOS/ORGANIZAÇÃO DA PLATAFORMA INN
            </h2>
          </header>
          <div className={'double a module-spaces tablet-compact'}>
            <div>
              <p>
                Para a construção de sua Base de Dados com aproximadamente 800
                programas de todo o mundo a INN consultou sua equipe de
                Consultores Globais de Justiça, assim como a conselheiros
                acadêmicos e a membros de variadas fundações. A INN também fez
                uma busca sistemática pela internet utilizando palavras-chave
                juntamente com o nome de países (prisão”, “reformulação
                prisional”, “justiça restaurativa”, alternativas ao
                encarceramento”, “penas alternativas”, “desencarceramento”).
                Foram incluídos todos os programas com um site operacional e
                informações de contato. Até onde sabemos, este banco de dados é
                o único mapeamento global deste tipo e extensão, mas não
                pleiteamos que seja absoluto. As buscas na internet foram
                conduzidas principalmente em inglês e espanhol. 
              </p>
              <p>
                Para selecionar os programas para a seção Exploração Profunda da
                plataforma, a INN pediu a seus Consultores Globais de Justiça
                que recomendassem programas especialmente eficazes; consultando
                também pesquisadores e ativistas locais. A INN deu prioridade
                aos programas em que as pessoas diretamente impactadas tem
                protagonismo, porque acreditamos, nas palavras de JustLeadership
                EUA, que “as pessoas mais próximas do problema estão mais
                próximas da solução.” P
              </p>
              <p>
                Ao categorizar os programas em sua Base de Dados, a INN utilizou
                informações disponíveis ao público. É comum que uma
                organização/instituição tenha vários tipos de programas, cada
                qual listado como um programa separado. Muitas organizações
                oferecem combinações de serviços e ativismo, não os considerando
                “programas” separados; nós categorizamos tais trabalhos
                sinérgicos como diferentes tipos de programas.
              </p>
            </div>
            <div>
              <p>
                As organizações participantes da plataforma Exploração Profunda
                receberam um questionário com 50 perguntase (
                <span className="strong">
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                  >
                    aqui
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/2bs7ebzwYpqUTW0e38aVz4/8b82c979473cd3023d7dc7edfe07a02b/INN_Alternatives_to_Incarceration_Questionaire_.pdf"
                  >
                    aqui
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                  >
                    aqui
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/5MOyuKg9Phsrh1yLhcub56/def96255a13b7ba334ff22bfa22bf77f/INN_DirectServices_Innovative_Alternatives_to_Traditional_Prisons_Questionaire_.pdf"
                  >
                    aqui
                  </a>
                </span>
                ) sobre seu trabalho, métodos, teorias a respeito de mudanças,
                entre outras; os questionários continham perguntas abertas e de
                múltipla escolha, de modo a coletarmos informações qualitativas
                e quantitativas sobre cada programa. 39 organizações receberam
                também pesquisas (
                <span className="strong">
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/1HPZ9vmVLMG2nZrwsZu6X4/87c80904023e859a826f35a97e07cbeb/INN_GlobalAssessment_ClientSurvey.docx"
                  >
                    client surveys
                  </a>
                </span>
                ) para repassar a cinco participantes atendidos por elas, para
                termos informações qualitativas sobre os programas.
              </p>
              <figure className={'mn'}>
                <img
                  src="https://www.genepi.fr/wp-content/uploads/2018/03/Re%C2%A6%C3%BCflexion-et-formation.jpg"
                  alt="Placeholder"
                  width="620"
                  height="555"
                />
              </figure>
              <p>
                52 programas na plataforma de Exploração Profunda foram
                visitados pela Diretora Executiva da INN Dra. Baz Dreisinger, o
                que incluiu ao menos um programa de cada região geográfica.
                Foram feitas entrevistas com representantes-chave de cada
                organização e com clientes.
              </p>
              <p>
                Alguns programas foram convidados a participar da plataforma de
                Exploração Profunda mas não puderam fazê-lo, por diferentes
                razões. A INN considerou alguns deles tão vitais que criou
                páginas para apresentar sua missão, abordagem e casos cobertos
                pela mídia sem no entanto incluir dados mais abrangentes por não
                ter conseguido levantá-los até o momento.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'}>
          <div className={classnames('double', 'a', 'tablet-compact')}>
            <div>
              <p className={classnames('strong', 'm40')}>
                Ao selecionar programas para a plataforma de Exploração
                Profunda, foi feito um esforço para equilibrar diversos fatores
                e permitir:
              </p>
              <hr />
              <ul>
                <li>
                  A inclusão de programas de uma grande variedade de regiões
                  geográficas
                </li>
                <li>
                  Programas tanto de âmbito nacional quanto de menor escala
                </li>
                <li>
                  Uma mistura relevante de programas sistêmicos, de amplo
                  alcance, e outros de serviços diretos, focados na resolução de
                  problemas espinhosos de indivíduos e no salvamento de vidas
                  aqui e agora
                </li>
                <li>
                  A inclusão tanto de programas tradicionais e estabelecidos
                  quanto de organizações menores e mais novas, que talvez ainda
                  não tenham resultados testados e aprovados mas cujos modelos
                  sejam promissores e inovadores e que venham a beneficiar-se de
                  estar numa plataforma global.
                </li>
              </ul>
              <hr />
              <p>
                <span className="strong">NOTA:</span> A lista não inclui
                prevenção – seja por meio de oportunidades de serviços sociais,
                intervenções comunitárias (em especial para jovens) ou
                desenvolvimento econômico – por estar além do escopo da
                plataforma INN no momento.
              </p>
              <p>
                A INN observou também trabalhos focados em relevantes categorias
                de diretamente impactados:
              </p>
              <ul>
                <li>Grupos super-representados no sistema penal</li>
                <li>Mulheres no sistema penal</li>
                <li>Detentos Reencarcerados/Provisórios </li>
                <li>Jovens/Menores de idade</li>
                <li>Familiares dos encarcerados</li>
                <li>Detentos LGBTQ </li>
              </ul>
            </div>
            <div>
              <p className={classnames('strong', 'm40')}>
                Identificar as categorias organizadoras da plataforma foi o
                primeiro passo para estabelecer um campo em comum aos programas
                e criar uma espécie de mapa das soluções. As categorias são:
              </p>
              <hr />
              <ul>
                <li>Alternativas ao Encarceramento </li>
                <li>Trabalho por Políticas Públicas </li>
                <li>
                  Serviços Diretos àqueles impactados pelo sistema prisional
                  (encarcerados, egressos e seus familiares)
                </li>
              </ul>
              <hr />
              <p>
                <span className="strong">NOTA: </span>Definir o grau de
                “sucesso” de um trabalho de reformulação prisional é algo
                complexo e cheio de nuances. A reincidência costuma ser
                utilizada como medida, mas pesquisadores e praticantes com
                frequência comentam que tais números são enganadores e
                simplistas. (Brasil e El Salvador, por exemplo, apresentam
                menores índices de reincidência que o Chile mas isso não
                necessariamente indica que os sistemas prisionais daqueles
                países sejam melhores, indicando antes que no Chile há mais
                gente entrando e saindo do sistema prisional em decorrência das
                sentenças curtas). Dada esta realidade, a INN perguntou de forma
                explícita às organizações o que elas consideram sucesso (“O que
                tornaria seu trabalho desnecessário?” “Qual a sua visão de
                mudança transformativa no longo prazo?”) e procurou levantar
                dados qualitativos e quantitativos dos programas que exibissem
                seu trabalho. A seção de Reflexões de Largo Escopo discute esse
                tema e a questão das práticas baseadas em evidências.
              </p>
              <p>
                <span className="strong">NOTA: </span>Em nome da acessibilidade
                — um dos princípios básicos de todo o trabalho da INN — esta
                plataforma utiliza terminologias populares que admitimos ser
                problemáticas. Conforme mencionado, “alternativa ao
                encarceramento” coloca, erroneamente, a prisão no centro do
                sistema judiciário. Termos como “rehabilitação” e
                “reentrada”/“reinserção” dão a entender que pessoas encarceradas
                foram antes habilitadas/inseridas na sociedade, o que sabemos
                ser o oposto da verdade: em termos globais, os presos
                originam-se de comunidades as mais marginalizadas e carentes,
                sendo qualquer oportunidade oferecida a eles dentro das prisões
                muito provavelmente a primeira chance de mudança que tiveram.
              </p>
              <p>
                <span className="strong">NOTA: </span>A seção Exploração
                Profunda desta plataforma não é uma avaliação de programas por
                meio de uma ferramenta de medida universal; os programas foram
                selecionados porque já eram tidos como valiosos e significativos
                como modelos potentes. Esta seção exibe as lutas e forças em
                comum de programas em diferentes países, realçando a força de
                trabalhos em diferentes contextos. Há uma discussão mais
                aprofundada sobre o tema na seção das Reflexões de Largo Escopo.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-featured'} id={'common'}>
          <h1>LUTAS & FORÇAS EM COMUM</h1>

          <figure>
            <img
              src="https://i.vimeocdn.com/video/693991407.webp?mw=1440&mh=710&q=70"
              alt="Placeholder"
              width="1440"
              height="710"
            />{' '}
            <i className={'icon-pluses'}></i>
          </figure>
        </article>
        <article id={'database'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              BASE DE DADOS PRINCIPAL (783 PROGRAMS)
            </h2>
          </header>
          <div>
            <h3 style={{ textAlign: 'center' }}>Figura 1</h3>
            <p style={{ textAlign: 'center' }}>
              Base de dados principal de programas por região e tipo
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={DBProgramsByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={DBProgramsByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Tabela 1</h3>
            <p style={{ textAlign: 'center' }}>
              Base de dados principal de programas por região e tipo
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="http://images.ctfassets.net/fhwlk5jsnns3/2PMzhwIhlBKpTtd7G6fzwl/d9e0be0f4957b90c6a8558eb357c3569/PR_Table_1"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              EXPLORAÇÃO PROFUNDA DE PROGRAMAS (81 PROGRAMAS)
            </h2>
          </header>

          <div>
            <h3 style={{ textAlign: 'center' }}>Figura 2</h3>
            <p style={{ textAlign: 'center' }}>
              Exploração Profunda de programas por região e tipo
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={INNPartnerByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={INNPartnerByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Tabela 2</h3>
            <p style={{ textAlign: 'center' }}>
              Exploração Profunda de programas por região e tipo
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="http://images.ctfassets.net/fhwlk5jsnns3/1ViAP6yLKvU6MdsyvwAjkm/1a7fdf4c31d4ddba76568028c9c45ee5/PR_Table_2"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
        </article>
        <article className={classnames('module-wide', 'overlay-b')}>
          <div id={'alternatives'}>
            <header className={'text-center'}>
              <h2 style={{ color: 'black' }}>ALTERNTIVAS AO ENCARCERAMENTO</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Há no mundo uma escassez de alternativas ao encarceramento
                  (AAEs). A INN encontrou bem mais programas de serviços diretos
                  e de políticas públicas do que AAEs; de todos os programas na
                  Base de Dados na data de levantamento apenas 14% (111
                  programas) eram AAEs (comparados com 60% de serviços diretos e
                  26% de políticas públicas). Entre os programas na plataforma
                  de Exploração Profunda, 63% das AAEs nomearam como seus
                  objetivos imediatos e de curto prazo a “condução de práticas
                  de justiça restaurativa”, o que indica uma grande necessidade
                  de desenvolvimento e apoio a novos programas nessa área.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Quase um terço (31.1%) dos programas AAE na Exploração
                  Profunda indicaram como o maior problema que precisam resolver
                  a “discriminação/super-representação de determinados grupos
                  (étnicos ou de cultura indígena) nas prisões”.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Nos locais em que existem bons programas AAEs (em especial de
                  justiça restaurativa), eles quase sempre se restringem a
                  menores de idade. Aparentemente as instituições hesitam em
                  oferecer oportunidades semelhantes a adultos — que, afinal de
                  contas, um dia foram menores de idade diante de um sistema que
                  os prejudicou.
                </p>
                <figure>
                  <img
                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                    src="https://cdhep.org.br/wp-content/uploads/2019/04/Curso_V%C3%ADtima_Ofensor_Comunidade_1.jpg"
                    alt="Placeholder"
                    width="415"
                    height="200"
                  />
                </figure>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Programas AAE já têm um longo histórico: em média 18 anos —os
                  programas de maior duração nas três grandes categorias
                  examinadas na Exploração Profunda. Programas AAE têm amplo
                  alcance, exibindo o maior número médio de participantes entre
                  as três categorias de programas. Costumam ter o maior número
                  de funcionários e também contar com a ajuda de voluntários; em
                  média 30% dos indivíduos envolvidos nessas organizações não
                  recebem salários. AAEs têm a maior probabilidade de ter ao
                  menos um encarcerado ou egresso entre seus funcionários (30%
                  dos programas).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Para a metade dos AAEs, a visão de mudança transformativa de
                  longo prazo foi “Sentenças alternativas/Justiça restaurativa
                  como principais medidas contra crimes ou condenações.”
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  AAEs custam bem menos do que programas de serviço direto (seja
                  o custo total dos programas, seja o custo por beneficiário).
                  Há um amplo espectro de gastos anuais entre os programas (de
                  US$ 4000 a 15 milhões), mas o mais caro programa de
                  alternativa ao encarceramento ainda custa três vezes menos do
                  que o mais caro programa de serviços diretos.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'restorative'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>JUSTIÇA RESTAURATIVA</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">NOTA: </span>Muitos dos programas AAE
                  envolvem aspectos de justiça restaurativa e jurisprudência
                  terapêutica (na Nova Zelândia, por exemplo, a Lei de
                  Condenação de 2002 contém claras provisões que obrigam juízes
                  a levar em conta processos restaurativos). A INN categoriza um
                  programa como de justiça restaurativa apenas se ele próprio
                  assim se denomina.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Em boa parte do mundo, a justiça restaurativa é ainda
                  desconhecida do grande público — há um sério problema de
                  relações públicas. Um programa norte-americano precisou mudar
                  a descrição de seu trabalho de “conferências comunitárias”
                  porque era confundido com uma empresa que alugava espaços para
                  conferências; também observaram que eram uma opção
                  desconhecida para muitas escolas e sistemas judiciários da
                  área, ainda que tenham desenvolvido tal trabalho por mais de
                  duas décadas.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Facilitadores poderosos que montam o que a Restorative
                  Response Baltimore (EUA) chama de “modelo de crescimento
                  contínuo” são essenciais para o sucesso de qualquer
                  intervenção por meio de justiça restaurativa, em oposição aos
                  treinamentos únicos de facilitadores de todo tipo, que em
                  geral diluem os processos e conceitos ao passá-los a um grande
                  número de pessoas que talvez não se adequem ao delicado papel
                  de facilitadores.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Os programas de justiça restaurativa foram selecionados para a
                  plataforma de Exploração Profunda em parte porque tiram as
                  pessoas da rota tradicional do sistema criminal de justiça em
                  lugar de funcionar como um apêndice a ele (como por exemplo os
                  programas que trabalham com encarcerados). A INN aprecia tais
                  programas por serem abordagens não prisionais da justiça.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'diversion'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>ALTERNATIVAS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Muitos programas alternativos envolvem o encaminhamento de
                  pessoas a programas externos em lugar da prisão (por exemplo
                  para o tratamento de dependências a drogas ou álcool,
                  mentorias, serviços de apoio ao luto etc.); e por conta disso
                  43% das AAEs identificaram como fator mais importante para o
                  sucesso a “colaboração dos parceiros externos”. Muitas
                  organizações apontam, no entanto, que tais programas
                  encontram-se esvaziados de recursos ou com graves falhas — em
                  especial referentes à sensibilidade cultural para com grupos
                  super-representados. Aboriginal Legal Services (Canadá) atende
                  a esta questão de forma valiosa: tornou-se um programa
                  completo dirigido por nativos para nativos.
                </p>
                <img
                  className={'desktop-only'}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/bxp41924s.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://denhaagfm.nl/wp-content/uploads/2016/04/HALT-foto-1.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Planos alternativos holísticos, personalizados são essenciais
                  — não há uma receita única que sirva a todos. Nos programas
                  mais eficazes, agências diversas trabalham em sinergia,
                  conhecendo cada indivíduo a quem atendem e funcionando em
                  colaboração com as famílias, os assistentes sociais, e os
                  sistemas de saúde e educação. Na Nova Zelândia, por exemplo,
                  os programas Family Group Conferences, Rangatahi e o tribunal
                  de menores trabalham juntos para encontrar soluções caso a
                  caso; o Red Hook Community Justice Center (EUA) descreve-se
                  “centrado nas pessoas” e não categoriza clientes nem mesmo
                  pelo tipo de crime. Nos Países Baixos a organização Halt
                  acompanha 16.000 jovens a cada ano — um terço de todos os
                  menores de idade presos no país — mas ainda assim cria planos
                  individualizados para eles, dedicando em média três meses a
                  cada processo. O programa inova e evolui de maneira
                  consistente, promovendo a prevenção em seu trabalho junto às
                  escolas, experimentando contatar grupos de diferentes idades e
                  utilizando games e realidade virtual nas tarefas de estudo.
                  75% daqueles que passam por seus programas alternativos não
                  cometem novas ofensas.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Os tribunais indígenas da Nova Zelândia e da Austrália, assim
                  como o Red Hook Community Justice Center (EUA), revelam como
                  alterações pequenas, sutis, nos procedimentos legais podem
                  humanizar e tornar culturalmente relevantes o que os
                  fundadores do Koori Court na Austrália chamam de “tradição em
                  lugar de leis”: menos formalidade; atribuição de um papel
                  central ao acusado no processo, com direito a expressar-se;
                  ênfase na comunidade por meio de rituais, cânticos, toque do
                  rosto e das mãos, presença de anciões/ pessoas respeitadas;
                  uso de linguagem comum em lugar de juridiquês; remoção de
                  barreiras físicas colocadas pelo sistema jurídico branco
                  europeu e disposição de assentos que reflita mais igualdade,
                  sem hierarquias de poder (o juiz no mesmo plano que os
                  presentes e não numa plataforma elevada); processo com
                  desenrolar calmo em vez de justiça como linha de montagem;
                  atenção para o design do tribunal: luz natural e/ou murais
                  convidativos, ausência de madeiras escuras; linguagem
                  respeitosa (“por favor” e “obrigado”) durante todo o processo.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'mediation'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>MEDIAÇÃO</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Em países de todo o mundo os sistemas legais encontram-se
                  abarrotados, com poucos advogados (para clientes que não podem
                  pagar) — sendo o melhor encaminhamento o da justiça de baixo
                  para cima, em vez de cima para baixo: desviar as pessoas antes
                  que entrem na esfera legal. Nem sempre é preciso que sejam
                  advogados a fazer mediações; sendo vital que haja
                  empoderamento legal e conselheiros da comunidade — como os do
                  Madaripur Mediation Program em Bangladesh e do Red Hook
                  Community Justice Center’s Peacemaking Program (EUA), no qual
                  conselheiros são treinados pela nação Navajo. Iniciativas de
                  pacificação apoiadas pela comunidade e desenvolvidas pelo
                  governo, como o Red Hook Community Justice Center, são uma
                  área que convida a muito crescimento.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Na sociedade Navajo, k’e é um sistema de parentesco que
                  privilegia valores como respeito, gentileza, cooperação,
                  amizade e relações recíprocas. Comportamentos que desrespeitam
                  as regras do k’e são chamados de “atos de alguém que não tem
                  parentes”. Programas de mediação bem-sucedidos utilizam tais
                  valores da comunidade e da família para cultivar a paz.
                </p>
              </div>
            </div>
          </div>

          <hr />
          <div id={'advocacy'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>POLÍTICAS PÚBLICAS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  As organizações de todo o mundo fazem pressão por basicamente
                  as mesmas coisas: 1. Reformulação das políticas relativas a
                  pequenos delitos e/ou as guerras às drogas; 2. Reformulação
                  das condenações; 3. Melhoria das condições da carceragem; 4.
                  Políticas de prisão provisória e fiança. A prisão provisória é
                  especialmente relevante em todos os países, apontada por 50%
                  dos programas de políticas públicas na plataforma Exploração
                  Profunda como uma das áreas que mais desejam influenciar.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/8109-org.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Muitas organizações estão utilizando métodos semelhantes para
                  lidar com as questões citadas: 1. Contato com decisores; 2.
                  Litígios estratégicos; 3. Mudança das narrativas públicas por
                  meio de campanhas de mídia e/ou artes; 4. Produção de
                  pesquisas e relatórios. 65% dos programas justificaram que “se
                  decisores tiverem informações mais precisas, irão tomar
                  melhores decisões”, enquanto 45% argumentaram que “se
                  decisores tiverem mais empatia pelas vidas humanas e histórias
                  afetadas por suas escolhas, irão tomar melhores decisões”.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Algumas questões muito importantes se mostram excelentes alvos
                  para litígios estratégicos em diferentes países: reformulação
                  do sistema d fiança; barreiras que impedem o acesso a
                  empregos, moradia e educação de egressos (conhecido nos EUA
                  como movimento “Ban the Box” — fora com a caixinha); a questão
                  das férias forenses durante as quais juízes se ausentam por
                  meses, o que paralisa o sistema e superlota cadeias. A INN
                  argumenta que a justiça não tira férias — nunca.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'directServices'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>SERVIÇOS DIRETOS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  “A falta de programas de reabilitação” foi apontada por 20%
                  dos 81 programas de Serviços Diretos da plataforma de
                  Exploração Profunda como seu principal problema ou necessidade
                  a resolver. No entanto, na Base de Dados 60% dos programas de
                  todo o mundo são de “Serviços diretos” (ver Figura 1); e
                  desses a maior parcela está na categoria “Reentrada” (153
                  programas, 20% do total) e “Outros Serviços de Reabilitação”
                  (79 programas, 10% do total). Apenas 14% dos programas (111)
                  são de Alternativas ao Encarceramento. Uma conclusão a que
                  chegamos é de que: muitas vezes o esforço de salvar vidas em
                  meio aos tumultos do encarceramento através de serviços
                  diretos — em especial focados na reentrada — absorvem os
                  recursos de iniciativas de maior escopo para desfazer o
                  encarceramento. Ainda assim tais esforços não são suficientes,
                  as organizações sentem falta de recursos
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Os múltiplos componentes do trabalho de serviço direto se
                  justapõem; é virtualmente impossível atender a apenas uma
                  necessidade de alguém envolvido com a justiça sem ter que
                  lidar com muitas outras necessidades. É essencial, portanto,
                  contar com equipes multidisciplinares ou holísticas. A AJEM
                  (Líbano), por exemplo, reúne advogados, assistentes sociais e
                  psicólogos de modo a fazer o que descrevem como uma “abordagem
                  multidimensional pela prevenção, reabilitação e reinserção
                  social”. O Red Hook Community Justice Center (EUA) é
                  multidimensional e multi-jurisdicional, colaborando não apenas
                  com procuradores e tribunais como também com as administrações
                  de habitação, educação, saúde e justiça, tanto governamentais
                  quanto ongs.
                </p>
              </div>
            </div>
            <hr />
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>ASSISTÊNCIA LEGAL/FIANÇA</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')} id={'legal'}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Em muitos países, em especial no Hemisfério Sul, a lei
                  funciona como um poder neocolonial, governando a vida das
                  pessoas sem que se deem conta. Muitos sistemas legais mundo
                  afora têm raízes coloniais e foram impostos em contextos
                  sociais a que são muito mal adaptados; a perfeita imagem dessa
                  discrepância é o juiz africano num país como Gana usando toga
                  e peruca branca num tribunal quente e abafado a milhares de
                  quilômetros da Inglaterra. MyJustice Myanmar lembra que o som
                  da palavra “lei” significa “mentira” na língua karen.
                  Organizações como CHREAA (Malawi), MyJustice (Mianmar) e
                  Advocaid (Serra Leoa) trabalham para tornar a lei acessível e
                  compreensível para suas comunidades, fazendo uso de teatro e
                  arte para alcançar o público, em especial nas áreas rurais
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://advocaidsl.org/wp-content/uploads/2018/12/IMG_0774.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Globalmente, organizações empregam abordagens em comum para
                  reduzir e prestar auxílio a populações encarceradas, em
                  especial em prisão preventiva. Tais abordagens incluem: 1.
                  Fornecer apoio legal tão logo quanto possível, no início do
                  processo (na Índia isso é feito pela CHRI já nas delegacias,
                  por exemplo), acompanhado de uma gama de serviços auxiliares
                  (Advocaid, Serra Leoa; Brooklyn Defender Services, EUA); 2.
                  Fazer avaliações de risco levando em conta diferenças
                  culturais para livrar pessoas de prisões preventivas (JJAI,
                  México); 3. Fornecer tribunais volantes e treinamento atrás
                  das grades para liberar pessoas da detenção (Justice for All
                  Programme, Gana); 4. Levantar recursos para fianças e
                  pressionar pela reformulação do sistema de fianças (The Bail
                  Project, EUA); 5. Oferecer cursos de direito e certificação a
                  encarcerados para que se tornem advogados e técnicos em
                  direito e auxiliem a resolver casos atrasados (African Prisons
                  Project, Quênia & Uganda); 6. Oferecer assistência legal e
                  educação jurídica/ habilidades de empoderamento (CHREAA,
                  Malawi).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Organizações que utilizam abordagens holísticas de assistência
                  legal, como Brooklyn Defender Services (EUA) e Advocaid (Serra
                  Leoa), oferecem uma gama de serviços sociais a seus clientes,
                  sabendo que as pessoas que se enredam no sistema jurídico
                  criminal com frequência têm necessidades além da assistência
                  legal.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'reentry'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>REENTRADA</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  “Reentrada/Reintegração” é a maior categoria de programas
                  tanto na plataforma de Exploração Profunda quanto na listagem
                  da Base de Dados, o que indica o estado crítico da questão em
                  todo o planeta e a quantidade de recursos dedicados a
                  resolvê-la. 34% dos programas de todas as modalidades de
                  serviço direto na Exploração Profunda citaram como seus
                  objetivos “Melhorar condições, recursos e perspectivas de
                  reintegração após a soltura (27% definiram como “Melhorar as
                  condições de bem-estar e conhecimentos durante o
                  encarceramento”).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Barreiras que impedem o acesso a empregos, moradia e educação
                  de egressos são uma realidade global. Ainda assim, os esforços
                  para removê-las — tanto de modo legal (via litígios
                  estratégicos e outras táticas de pressão) quanto cultural (via
                  novas narrativas a respeito de egressos do cárcere e aceitação
                  da comunidade) — são escassos em quase todos os países com
                  exceção dos EUA, onde ganharam o nome de movimento “Ban the
                  Box” (elimine a caixinha, referindo-se à opção “você tem
                  antecedentes criminais?” em formulários que candidatos
                  precisam ticar). Mesmo em lugares como os Países Baixos, onde
                  as barreiras não são tão proibitivas, cidadãos egressos da
                  carceragem imaginam que elas existam e fogem de oportunidades.
                  Barreiras informais, como estigma e vergonha, são difíceis de
                  medir.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Com a exceção de Singapura, governos não contam com unidades
                  dedicadas à reentrada, locais ou nacionais, nem tampouco com
                  bancos de dados de empregos de reentrada (como o de Singapura,
                  que conta com 5.520 empregadores registrados). Eis uma área de
                  grande necessidade e potencial de crescimento.
                </p>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Duas coisas são fundamentais para o sucesso de programas de
                  reentrada: continuidade, um acompanhamento que comece com a
                  pessoa encarcerada e continue quando ela é solta (Singapura
                  chama a este acompanhamento de “cuidado minucioso”, que
                  caracteriza seus programas); e uma abordagem holística para os
                  desafios generalizados da reentrada: encontrar moradia,
                  arrumar emprego, conseguir apoio emocional e pós-traumático.
                  Esses fatores distinguem a categoria Reentrada de outros
                  Serviços Diretos para encarcerados, focados em criar
                  oportunidades de trabalho apenas atrás das grades. A INN
                  acredita que todo trabalho iniciado dentro das prisões precisa
                  continuar for a, acompanhando os egressos.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  O ingrediente que faz toda a diferença em qualquer programa de
                  reentrada são as pessoas. Nenhum aplicativo ou algoritmo
                  substitui seres humanos que acompanhem de perto egressos do
                  sistema prisional de volta para casa. SCORE (Singapura) mantém
                  uma equipe volante de coaches que visitam e dão apoio a seus
                  clientes conforme se ajustam à vida nos ambientes de trabalho
                  (e provavelmente contribuem para a taxa de retenção de emprego
                  de 81% entre os cidadãos que retornam). Community Restorative
                  Centre NSW (Austrália) usa um “modelo de gerenciamento de
                  casos intensivo” que inclui, conforme seus funcionários
                  definem, “não ser muito crítico” ao acompanhar os cidadãos que
                  retornam e manter contato por celular oferecido no trabalho.
                  <br />
                  As moradias para egressos cuidadas por Exodus (Países Baixos)
                  contam com mentores que moram ali e oferecem apoio e amizade
                  24 horas por dia. “Dedicação e paixão dos funcionários” foram
                  fatores identificados por 28.4% dos programas na Exploração
                  Profunda como essenciais para bons resultados; nas discussões
                  da INN com clientes, a necessidade do contato pessoal foi
                  sempre mencionada.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Para muitos, a reintegração social é um processo longo, com
                  muitos altos e baixos. Isso significa que o apoio precisa
                  perdurar por vários meses quando não um ano inteiro. Exodus
                  (Países Baixos) menciona um tipo de apoio que continua após os
                  egressos deixarem as moradias do programa mas ainda desejarem
                  manter contato com os mentores e ter acesso oportunidades.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  A moradia é uma questão universal para as pessoas que saem das
                  prisões, sendo extremamente necessário encontrar soluções
                  criativas, em especial nas cidades onde o aluguel é alto.
                  Pension Skejby (Dinamarca) mantém um espaço que abriga tanto
                  estudantes quanto egressos, que dividem os espaços comuns e
                  mantém uma comunidade juntos. The Homecoming Project (EUA)
                  conecta pessoas que têm espaço em suas casas com egressos do
                  sistema prisional.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'education'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>EDUCAÇÃO ATRÁS DAS GRADES</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Programas robustos de educação nas prisões são vitais, uma vez
                  que os níveis de escolaridade têm relação direta com a
                  segurança da comunidade — algo que o senso comum e as
                  pesquisas comprovam. Apesar disso, tais programas são
                  insuficientes mesmo onde mais existem, como na Europa.{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://antoniocasella.eu/nume/Hawley_UE_education_may13.pdf"
                  >
                    {' '}
                    Um estudo de 2013
                  </a>{' '}
                  sobre educação e treinamento no cárcere apontou que na maioria
                  dos países da União Europeia a participação nos programas
                  educativos nas prisões era inferior a 25%. As barreiras para a
                  participação incluíam experiências negativas anteriores com o
                  sistema de ensino, o fato de que havia mais incentivo para o
                  trabalho do que para o aprendizado no cárcere, e um número
                  limitado de vagas nos programas.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Todo tipo de educação atrás das grades é essencial, em
                  especial a alfabetização e o ensino de matemática básica.
                  Ainda assim, não se devem descartar programas que ofereçam
                  cursos superiores. Eles não apenas mudam a percepção do
                  público a respeito do que encarcerados são capazes de fazer
                  como representam um investimento em futuras lideranças. Mudam
                  os parâmetros para oferecer reais oportunidades — em lugar de
                  apenas permitir a sobrevivência. A criação de empregos de
                  baixa renda não resolve as desigualdades sistêmicas, mas o
                  investimento em educação de qualidade, oportunidades
                  verdadeiras e liderança visionária podem ser o caminho para
                  mudanças de amplo alcance.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://images.squarespace-cdn.com/content/57cd909c197aea06e48be99b/1537440132261-JEW860FOZYJPQ5GB8NNL/IMG_2054.JPG?format=750w&content-type=image%2Fjpeg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  No levantamento feito com clientes de três programas de
                  educação de nível universitário oferecidos na carceragem de
                  três diferentes países (Quênia, EUA e Itália) em três
                  diferentes continentes, estudantes elogiaram as iniciativas de
                  modo semelhante. Eles enfatizaram o quanto a educação superior
                  os empoderou, ajudou-os a enxergar o futuro de maneira mais
                  positiva e a dar valor a si próprios (“Continuar os estudos
                  comprovou o meu valor e minha fé de que existe vida após o
                  encarceramento,” disse um estudante do NJ Step (EUA);
                  “Confiança em mim mesmo, possibilidade de ter um futuro, ver a
                  minha vida em cores em lugar de preta e branca” foi como um
                  estudante do Articolo3 (Itália) descreveu o valor de uma
                  educação universitária dentro da carceragem; um estudante do
                  African Prisons Project (Uganda, Quênia) falou sobre sua
                  “mudança de atitude em relação à vida e percepções também,
                  empoderamento através da aquisição de conhecimentos… respeito
                  por mim mesmo e pelos outros e minha dignidade realçada”,
                  outro estudante da APP observou que “o programa provou que os
                  presos têm soluções para seus próprios problemas, tudo o que
                  precisam é de apoio”). Tanto os estudantes do APP (Uganda,
                  Quênia) quanto do NJ Step (EUA) afirmaram que os programas de
                  educação trouxeram também mais estabilidade para a prisão como
                  um todo.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  A INN acredita que as universidades tenham um papel essencial
                  na construção de comunidades mais seguras, e parcerias
                  inovadoras entre prisões e universidades apresentam uma
                  riqueza de possibilidades. Universidades, afinal de contas,
                  contêm trabalhadores em treinamento — futuros professores,
                  assistentes sociais e tantos outros — que muito se
                  beneficiariam de conhecer as populações encarceradas. Que, por
                  sua vez, também se beneficiariam de conhecer jovens e futuros
                  líderes construindo suas carreiras. Universidade de modo geral
                  são também entidades relativamente estáveis, isentas de
                  ligações políticas explícitas — algo raro em países que passam
                  por instabilidade política e econômica — e seriam muito
                  positivas para montar e manter programas de longa duração em
                  no sistema prisional.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'jobDevelopment'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                CRIAÇÃO DE EMPREGOS ATRÁS DAS GRADES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  O{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://prisonpolicy.org/reports/outofwork.html"
                  >
                    estudo norte-americano
                  </a>{' '}
                  mostrou que, em 2018, 27% dos egressos do sistema prisional
                  encontravam-se desempregados — uma taxa superior à de
                  desemprego nos EUA em qualquer período histórico e quase cinco
                  vezes superior à taxa de desemprego da população em geral.
                  Ainda que não existam estudos semelhantes para outros países,
                  a falta de empregos para pessoas que retornam das prisões é um
                  problema recorrente e apontado como crítico pelos parceiros da
                  INN.
                </p>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://static.wixstatic.com/media/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.webp"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Modelos cooperativos (como por exemplo Coostafe e Humanitas360
                  no Brasil) são especialmente interessantes por oferecerem a
                  detentos comissão pelas vendas e gestão do próprio trabalho,
                  impactando suas vidas após a soltura e resistindo ao paradigma
                  de trabalho prisional (e trabalho escravo) de presença tão
                  longa e opressiva na história do mundo.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  O levantamento feito com clientes demonstra que muitos
                  programas (por exemplo Nafisika no Quênia) almejam atingir um
                  objetivo e acabam fazendo muitas outras coisas além de
                  desenvolver habilidades na área profissional: alimentam a
                  confiança e o envolvimento com a comunidade, despertam o
                  interesse pela educação, aprimoram as relações interpessoais e
                  cultivam a empatia.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Programas inovativos focam em treinar seus clientes para
                  empregos em alta demanda, como horticultura (Hlumelilisa,
                  África do Sul), codificação de dados (The Last Mile, EUA) e
                  empreendedorismo (Nafisika, Quênia). Assim como os bons
                  programas de reentrada, eles também utilizam uma abordagem em
                  linha, auxiliando seus clientes depois da soltura para que
                  encontrem empregos.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'staffTraining'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                TREINAMENTO DE AGENTES PENITENCIÁRIOS
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  O fato de que esta é a segunda categoria com menor número de
                  programas na lista de Serviços Diretos na Base de Dados, com
                  apenas 14 programas no mundo voltados para o treinamento de
                  agentes penitenciários, é alarmante. Tal como as escolas só
                  podem ser de qualidade se tiverem bons professores, um sistema
                  penal é tão eficaz quanto seus funcionários. Há um interesse
                  crescente pelas condições das prisões e especialmente pela
                  arquitetura, mas sem uma mudança cultural a respeito dos
                  carcereiros — incluindo apoio, treinamento e recrutamento de
                  pessoas de mentalidade aberta para o trabalho — existe o risco
                  de outras mudanças serem apenas cosméticas..
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Uma cultura que tenha agentes prisionais em alta conta irá
                  atrair candidatos bem preparados para a posição. A escola
                  técnica da Noruega e Unlocked Graduates (Grã-Bretanha)
                  reconhecem este princípio, o primeiro oferecendo bons salários
                  e criando um bom ambiente de trabalho nas prisões; o segundo
                  procurando alterar a percepção sobre a carreira nos locais de
                  recrutamento de universitários e oferecendo um programa de
                  apoio e camaradagem para seus recrutas.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'rehabServices'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                OUTROS SERVIÇOS DE REABILITAÇÃOI ATRÁS DAS GRADES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <img
                  style={{
                    minHeight: '275px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="http://www.insightprisonproject.org/uploads/1/1/6/0/11602085/1345745274.jpg"
                  alt="Placeholder"
                  width="360"
                  height="275"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  O levantamento feito com clientes de organizações de três
                  continentes (Líbano, El Salvador, Bélgica e Grã-Bretanha)
                  revelam os benefícios de esforços de reabilitação dentro das
                  prisões, seja por meio de teatro, resolução de conflitos ou
                  escrita criativa. A união e a confiança que tais programas
                  geraram nos grupos foram os aspectos mais citados:
                  “Comunidade, criar relacionamentos com os outros. Tornei-me
                  mais humanitário; comecei a ajudar os outros sem esperar nada
                  em troca” (AJEM, Líbano); “O programa nos permite pensar
                  juntos como um grupo em que cada um dá sua opinião. Nós
                  coexistimos como amigos e autores” (Contextos, El Salvador);
                  “Antes eu me sentia sozinho, não tinha com quem conversar…
                  Agora eu sei que tenho pessoas com quem me encontrar e falar.
                  Não me sinto tão sozinho” (De Rode Antraciet, Bélgica);
                  “Observei um grupo de prisioneiros sem nada em comum criar
                  amizade e se transformar em sua versão de unidade familiar,
                  mostrando cuidado e compaixão uns com os outros, muito bonito
                  de ver” (LEAP, Grã-Bretanha).
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'health'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                SAÚDE E SEGURANÇA ATRÁS DAS GRADES
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  Base de Dados, apenas 1% dos programas entra nessa categoria,
                  apesar dos números alarmantes de HIV/AIDS e epidemias de
                  tuberculose dentro das prisões, em especial na África e no
                  Sudeste Asiático.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  O impacto da guerra às drogas em populações de encarcerados em
                  todo o mundo não tem como ser exagerado. Apesar de haver um
                  lento movimento global pela legalização da maconha e o
                  tratamento de adição a drogas como uma crise de saúde pública
                  em lugar de um problema de justiça criminal — incluindo
                  abordagens de redução de danos —, a questão ainda precisa de
                  muito impulso para ter resultados tangíveis. Essa é uma área
                  que necessita de atenção em todo o mundo; uma mudança a sério
                  nas políticas relativas a drogas poderia reduzir drasticamente
                  as populações encarceradas.
                </p>
              </div>
              <div>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://justdetention.org/wp-content/uploads/2015/10/joke_cropped_hi_res-1024x741.jpg"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
            </div>
          </div>
          <hr />
          <div id={'innovative'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                ALTERNATIVAS INOVADORAS PARA PRISÕES TRADICIONAIS
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">NOTA: </span>Na Base de Dados, a
                  maior concentração de alternativas inovadoras encontra-se na
                  Europa.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  LUTA EM COMUM:
                </h3>
                <p>
                  “Prisões modelo” inteiras ou alas dentro de penitenciárias —
                  exceções progressistas isoladas dentro de sistemas retrógrados
                  — são valiosas como indicadores do que poderia ser a
                  realidade. No entanto, são também perigosas por tornarem-se um
                  substituto para uma mudança aprofundada do sistema, mantendo
                  as coisas como são e impedindo que haja uma verdadeira
                  transformação. O caso do Centro de Detenção em Pequena Escala
                  de Amsterdam Oeste, inicialmente um modelo experimental de
                  detenção em regime semiaberto de jovens à espera de
                  julgamento, é um exemplo de como, nas palavras de seu
                  fundador, “um pequeno centro impactou o país inteiro”: o
                  centro em 2019 estava para ser replicado em todo o país,
                  provocando o fechamento de centros de detenção de jovens e uma
                  mudança radical no sistema.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  FORÇA EM COMUM:
                </h3>
                <p>
                  Em resposta à pergunta “O que diferencia sua
                  instalação/instalações de prisões tradicionais?”, três dos
                  cinco programas nomearam “Princípios ou filosofia de punição”;
                  dois dos cinco mencionaram “Cooperação com comunidades locais
                  e indivíduos” e “Mais liberdade”.
                </p>
                <img
                  style={{
                    minHeight: '375px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://pulitzercenter.org/sites/default/files/styles/node_images_768x510/public/11-17-14/unusual-prison-11.jpg?itok=BrnW17ps"
                  alt="Placeholder"
                  width="360"
                  height="375"
                />
              </div>
            </div>
          </div>
        </article>
        <div id={'bigPicture'}>
          <article className={classnames('module-featured', 'box')}>
            <h2>PRINCIPAIS CONCLUSÕES DA PLATAFORMA INN</h2>
            <p>
              <span
                className={classnames('strong', 'text-uppercase')}
                style={{ fontStyle: 'italic' }}
              >
                Kua tawhiti ke to haerenga mai, kia kore e haere tonu; He tino
                nui rawa o mahi, kia kore e mahi nui tonu. <br /> <br /> Você já
                veio longe demais para não continuar; Você já fez demais para
                parar.
              </span>{' '}
              <br /> -Sir James Henare (Ngati Hine, 1989)
            </p>
            <figure className={'overlay-d'}>
              <img
                src="https://placehold.it/1440x710"
                alt="Placeholder"
                width="1440"
                height="710"
              />
            </figure>
          </article>
          <article className={'mn'}>
            <div className={classnames('double', 'a', 'tablet-compact')}>
              <div className={'m0'}>
                <ul>
                  <p>
                    <span className="strong">NOTA: </span>Os dados reunidos nos
                    questionários, assim como as visitas aos sites pela Dra.
                    Dreisinger e as entrevistas que conduziu, tanto formais
                    quanto informais, com as organizações e clientes permitem
                    uma série de reflexões de largo escopo sobre a plataforma e
                    o trabalho nas prisões em todo o mundo. Tabelas e gráficos
                    comparativos de respostas selecionadas dos questionários
                    obtidos dos programas na plataforma Exploração Profunda
                    podem ser vistos{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      aqui
                    </a>
                    ; e uma bibliografia relevante{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/4qE4rtxCNWUOUdpGizTuoG/a029e92dc23a6c5ad28ba03ed67bc796/GlobalAssessment_Bibilio.docx"
                    >
                      aqui
                    </a>
                    .
                  </p>
                  <li>
                    <span className="strong">PENSANDO FORA DA CAIXA: </span>
                    Não é apenas possível ser global e local ao mesmo tempo — é
                    muito benéfico fazê-lo. Quando saímos de nosso próprio
                    sistema para observar problema e soluções, a mente começa a
                    pensar de modo radical sobre o que é possível, não apenas
                    sobre o que existe. É frequente que mesmo trabalhadores da
                    justiça extremamente progressivos fiquem presos ao que
                    existe em lugar de se inspirarem pelo que poderia existir e
                    se libertarem para o que deveria existir. A trajetória das
                    práticas prisionais no mundo até foi de exportação do modelo
                    prisional norte-americano — um experimento histórico — para
                    o restante do mundo, primeiro no século 19 via colonialismo,
                    depois no século 20 pela globalização. Esse percurso pode
                    ser desfeito pela quebra do fluxo de ideias: é possível
                    partilhar abordagens progressistas norte-americanas e de
                    outros lugares, o processo de aprendizado pode e deve operar
                    em múltiplas direções.
                  </li>
                  <li>
                    <span className="strong">
                      REPRODUÇÃO DE PROGRAMAS PROMISSORES:{' '}
                    </span>
                    Já está acontecendo em variados locais do mundo. A prisão
                    Bastoy na Noruega foi imitada na República Tcheca, por
                    exemplo, e o Modelo de Mediação Madaripur de Bangladesh foi
                    reproduzido em Malawi. Tais processos, no entanto, não são
                    uma ciência exata, não há um copia-e-cola possível para a
                    justiça, sendo mais apropriado falarmos de inspiração em vez
                    de reprodução. Os programas precisam reconhecer as
                    especificidades culturais ao mesmo tempo que confirmam a
                    universalidade da pobreza, do racismo e da injustiça no
                    cerne dos sistemas prisionais.{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://theguardian.com/social-enterprise-network/2012/nov/06/social-franchising-how-what-benefits"
                    >
                      O modelo de franqui social
                    </a>{' '}
                    is é relevante aqui.
                  </li>
                  <li>
                    <span className="strong">MAIS DO QUE CONVERSA FIADA: </span>
                    Governos ao redor do mundo substituíram seus “Departamentos
                    de Penitenciárias” por “Departamentos de Correção”, fazendo
                    alarde da mudança de terminologia — que em geral não passa
                    de conversa mole. Governos continuam a investir pouco em
                    “correção” e colocam as prisões no fim da lista de suas
                    verbas. Como há pouco apoio do público para programas
                    dirigidos ao sistema prisional e como o discurso
                    predominante é punitivo — linha dura contra o crime em lugar
                    de abordagens inteligentes —, governos são pouco
                    pressionados a gastar recursos em correção, preferindo focar
                    em assuntos populares que dão retorno imediato (e os ajuda a
                    reelegerem-se). Seria de esperar que governos ditassem os
                    termos e, na falta de termos melhores, vendessem suas ideias
                    sobre justiça inteligente ao público e não vice-versa — mas
                    não é o que acontece na grande maioria dos países.
                  </li>
                  <li>
                    <span className="strong">
                      FALTA DE RECURSOS E SUSTENTABILIDADE:{' '}
                    </span>
                    O resultado do triste cenário acima descrito é devastador
                    para o campo da justiça. Entre os programas da Exploração
                    Profunda, o problema mais comum citado foi o da falta de
                    financiamento, seja anual, seja plurianual (69% mencionaram
                    isso). Ao funcionarem com verbas mínimas, as organizações
                    precisam fazer uso de trabalho voluntário, que não é
                    sustentável. Elas também se tornam incapazes de pensar seu
                    trabalho num escopo maior porque a falta de recursos faz com
                    que fiquem apagando incêndios no dia a dia. Situação que,
                    por sua vez, dificulta a ousadia, a inovação e a
                    autocrítica; e também leva a uma falta de solidariedade e
                    percepção para com outras organizações trabalhando no mesmo
                    campo em seus países — uma realidade intensificada pela
                    competição por recursos mínimos.
                  </li>
                  <li>
                    <span className="strong">MUDANÇA DA NARRATIVA: </span>
                    Para lidar com a crise de financiamento, é essencial
                    transformar a narrativa pública a respeito das prisões e das
                    pessoas dentro delas, substituindo o discurso de vingança
                    por outro de reparação. Quando o público geral toma
                    consciência de que as prisões não colaboram para comunidades
                    mais seguras e que a maioria dos encarcerados precisa de
                    ajuda, não de dor e punições — quando, em outras palavras, a
                    prisão se transforma numa causa popular e não, como é o caso
                    em praticamente todo o planeta, um assunto tabu que invoca
                    reações impensadas de punição —, o público exige mudanças.
                    Políticos então passam a prestar atenção e alocar verbas em
                    lugar de apenas falar, e financiadores se tornam mais
                    favoráveis a apoiar trabalhos concernentes ao sistema
                    prisional. Esta mudança já ocorreu nos EUA. Menos de dez
                    anos atrás, a reformulação das prisões era uma causa
                    impopular, enquanto hoje até mesmo celebridades abraçam a
                    causa publicamente. O papel das artes — filmes, exposições
                    artísticas, livros — é fundamental, uma vez que elas são
                    capazes de transformar corações e mentes (como fizeram o
                    documentário indicado ao Oscar{' '}
                    <span style={{ fontStyle: 'italic' }}>13th</span> e o livro
                    best-seller
                    <span style={{ fontStyle: 'italic' }}>
                      The New Jim Crow
                    </span>{' '}
                    nos EUA). Quase todos os parceiros da INN expressaram o
                    desejo de mais verbas e abertura para investir em
                    comunicação, para ampliar seu trabalho e alterar o discurso
                    público. Também mostraram-se frustrados com o
                    sensacionalismo da mídia ao cobrir crimes e divulgar
                    notícias sobre encarcerados, que mantém o público receoso,
                    em atitude condenatória e atrapalha de modo significativo o
                    trabalho das organizações. A força da opinião pública é uma
                    das razões para as limitações da prática baseada em
                    evidências, ainda que tenha grande valor: mesmo quando
                    governos detém informações e sabem muito bem o que
                    “funciona”, podem não fazer nada por conta da oposição dos
                    eleitores. Em todo o mundo, programas fortes se transformam
                    em peões na guerra entre partidos políticos — em detrimento
                    da segurança, paz e justiça das comunidades.
                  </li>
                  <li>
                    <span className="strong">
                      REESCRITA DAS NARRATIVAS DAS COMUNIDADES:{' '}
                    </span>
                    Novas narrativas e diferentes histórias são essenciais para
                    que comunidades acreditem e invistam em si. Quando uma
                    comunidade aceita a identidade de “gueto” tomado pelo crime,
                    aquela narrativa se torna confortável — a comunidade a
                    confirma. Quando, porém, aquele núcleo de pessoas vê a si
                    como uma comunidade empoderada, conhecida pela paz e a
                    justiça, ela trabalha para manter esta identidade positiva.
                    O trabalho de JustReinvest (Austrália) em Bourke demonstra
                    bem esse princípio. Através de um trabalho de justiça
                    baseado em reinvestimento, a comunidade conhecida por ter os
                    mais altos índices de criminalidade da Austrália reformulou
                    sua estrutura social e, ao fazê-lo, criou e revisou sua
                    própria narrativa — passou a ser uma comunidade pacífica em
                    lugar de “perigosa” — que por sua vez firmou sua nova
                    estrutura social. As narrativas que contamos a nós mesmos
                    sobre a nossa identidade têm um poder imenso sobre nós.
                  </li>
                  <li>
                    <span className="strong">FINANCIAMENTO PÚBLICO: </span>O
                    objetivo de programas inovadores deveria ser a
                    sistematização. É função do governo, afinal, criar
                    comunidades justas e pagar por programas e estruturas que as
                    produzam. Os números levantados pela INN na Exploração
                    Profunda a respeito de fontes de financiamento, no entanto,
                    demonstram que tal sistematização acontece de modo muito
                    irregular em todo o mundo. Um pouco mais da metade (54% de
                    todos os programas na Exploração Profunda) são financiados
                    por fontes não-governamentais, ainda que Serviços Diretos
                    (57%) e Alternativas ao Encarceramento (63%) ainda sejam
                    predominantemente bancados pelos governos{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (Detalhes aqui)
                    </a>
                    . Entre os programas financiados principalmente pelos
                    governos, menos da metade se beneficia de alocações de
                    orçamento, o resto dependendo de subsídios governamentais. O
                    financiamento governamental de programas relacionados a
                    encarceramento varia muito conforme a região. Na América do
                    Norte o financiamento pelo governo é próximo daquele por
                    fontes não-governamentais; na Europa e Oceania os programas
                    (mais de 70%) são financiados pelo governo; entre 70 e 100%
                    dos programas na Ásia, África e América Latina foram
                    financiados por fontes não-governamentais{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (Detalhes aqui)
                    </a>
                    . Nos locais em que há recursos governamentais, muitas
                    organizações se mostram frustradas com os rígidos requisitos
                    em termos de números e serviços medidos, que eles dizem
                    privilegiar a quantidade em detrimento do trabalho com
                    qualidade.
                  </li>
                  <li>
                    <span className="strong">
                      FONTES DE FINANCIAMENTO CRIATIVAS:{' '}
                    </span>
                    Entre os programas da plataforma de Exploração Profunda não
                    custeados por governos, as maiores fontes de financiamento
                    os doadores internacionais e as menores são o setor privado.
                    Recursos criativos como investimentos de impacto e modelos
                    de empreendimentos sociais foram citados como parte do
                    financiamento de apenas duas organizações. Dada a
                    importância de modelos financeiros que gerem recursos e
                    modifiquem os sistemas, pensar em aplicá-los na área da
                    justiça deveria ser uma prioridade promovida em oficinas e
                    convenções dinâmicas e acessíveis.
                  </li>

                  <li>
                    <span className="strong">COLABORAÇÃO: </span>43.2% dos
                    programas na plataforma de Exploração Profunda mencionam
                    “colaboração com parceiros (externos)” como um de seus
                    ingredientes de sucesso; tais parceiros incluem políticos
                    locais, o judiciário, agentes do governo e organizações
                    comunitárias{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (veja Figura 3)
                    </a>
                    .
                  </li>
                  <li>
                    <span className="strong">OUTROS DESAFIOS: </span>
                    Outros desafios mencionados pelos programas na Exploração
                    Profunda foram: Falta de espaço ou infraestrutura para
                    atividades (entre as Alternativas Inovadoras para Prisões
                    Tradicionais); Falta de apoio governamental ou político
                    (para Alternativas ao Encarceramento); e Instabilidade
                    política ou rotatividade (para os programas de Políticas
                    Públicas).
                  </li>
                  <li>
                    <span className="strong">
                      INOVAÇÕES SIMPLES QUE CAUSAM IMPACTO:{' '}
                    </span>
                    O trabalho com justiça requer inovações radicais. Ao mesmo
                    tempo, reduzir e dar apoio a populações encarceradas não
                    requerem novas ideias mas sim comprometimento e investimento
                    em abordagens simples porém impactantes — como tribunais
                    volantes, penas e medidas alternativas, procura de empregos
                    enquanto as pessoas ainda estão no sistema prisional em
                    lugar de ajuda apenas depois de egressas, entre outros. Em
                    muitos países, programas de reformulação das prisões
                    promovidos pelos governos funcionam em teoria mas não na
                    prática: as palavras e os modelos existem, mas não são
                    aplicados — em grande parte por falta de recursos e
                    consequente ausência de esforço para que aconteçam.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <span className="strong">O PEQUENO PODE SER BOM: </span>A
                    melhor justiça é individualizada — o que JustSpeak da Nova
                    Zelândia denomina de “feito sob medida” — o que torna
                    programas menores com frequência melhores, uma vez que são
                    mais personalizados e permitem variadas abordagens
                    multiculturais. A ampliação é necessária para provocar
                    mudanças sistêmicas, mas quando acontece sem recursos
                    suficientes para dar apoio às pessoas que estão no cerne do
                    trabalho de justiça — e quando acontece de modo precipitado,
                    antes que o programa esteja firmemente implantado — o
                    resultado pode ser um desastre. Há um lado positivo em uma
                    série de abordagens feitas sob medida, em especial por
                    permitirem pensamentos e experimentos inovadores. Uma
                    iniciativa digna de nota é o Rescaled, um movimento europeu
                    que defende centros de detenção pequenos, que, dizem eles,
                    “possibilitam caminhos de reintegração sob medida,
                    abordagens personalizadas, menos burocracia, uma dinâmica de
                    segurança mais eficaz e dá mais oportunidade aos detentos de
                    assumir responsabilidade e interagir com a comunidade”.
                    Quando se trata de prisões e reintegração, tamanho não é
                    documento — na verdade, o pequeno é melhor por permitir um
                    trabalho personalizado.
                  </li>
                  <li>
                    <span className="strong">
                      DISTÂNCIA DO JARGÃO TÉCNICO:{' '}
                    </span>
                    Organizações fortes passam longe de linguagem técnica e
                    jargões do meio, dando preferência a interações humanas
                    normais e a criar laços. As casas de reentrada em pequena
                    escala do Exodus (Países Baixos) são o lar não de “clientes”
                    mas de “participantes”, muitos dos voluntários se envolvem
                    como amigos e colegas, não prestadores de serviços, e não há
                    oferta de serviços de saúde mental nas casas porque “não
                    somos clínicas forenses”. Ruud Jacobs, fundador do Centro de
                    Detenção em Pequena Escala de Amsterdam Oeste, descreve o
                    método da instalação como “um movimento, não um método” e
                    explica que simplesmente “estar presente” e “fazer de jeito
                    normal” funciona para muita coisa, em especial para jovens
                    que por muito tempo foram “clinicizados” por agentes de
                    serviços sociais. Lá os agentes de segurança não são
                    “guardas” mas “anfitriões” e também se responsabilizam por
                    criar laços com os vizinhos.
                  </li>
                  <li>
                    <span className="strong">PROCESSOS SÃO ESSENCIAIS: </span>É
                    sempre melhor de baixo para cima do que de cima para baixo.
                    As organizações precisam montar programas com as
                    comunidades, não para elas. JustReinvest (Austrália)
                    provocou a participação da cidade de Bourke, em especial dos
                    idosos, ao empoderá-los para que determinassem onde e como
                    gostariam que fossem feitos investimentos; o programa não
                    chegou com um modelo pronto para implantar na comunidade já
                    cansada de tais imposições. O Red Hook Community Justice
                    Center seguiu o mantra de “ouvir primeiro” quando se tornou
                    o segundo tribunal comunitário de Nova York, deixando que os
                    membros da comunidade decidissem qual seria o foco do
                    tribunal, onde ele seria localizado e quais seriam seus
                    processos. Cada um dos programas do Centro de Inovação de
                    Tribunais — dos quais o Red Hook Community Justice Center é
                    um — tem um perfil único, determinado pelas necessidades do
                    local e da comunidade.
                  </li>
                  <li>
                    <span className="strong">ABERTURA: </span>
                    Quanto mais aberta é a instalação — quanto mais ela permite
                    saídas para o fim de semana, para trabalhar, estudar e
                    outros fins — tanto mais ela promove integração com a
                    comunidade, o que é essencial para uma justiça progressiva.
                    Juristas e praticantes mundo afora fazem bem em pressionar
                    por paredes porosas — que permitam aos encarcerados
                    participar de programas na comunidade — em lugar de montar
                    programas atrás das grades.
                  </li>
                  <li>
                    <span className="strong">PRINCÍPIOS: </span>
                    Entre os clientes de organizações globais, as mesmas
                    palavras foram usadas para descrever bons programas:
                    confiabilidade, empoderamento, família, respeito, visão de
                    longo prazo de mudanças transformativas, apoio, prestativo,
                    amigável, motivador, empoderar, escolhas, responsabilidade,
                    confiança, transformação. Expressões referentes a “sentir-se
                    humano” por conta de um programa eficaz também foram
                    utilizadas, assim como a vitalidade das dinâmicas de grupo e
                    a comunidade. As mesmas palavras foram repetidas para
                    definir seu trabalho: justiça, responsabilidade, inovação,
                    respeito, dignidade, comunidade (Figura 4).
                  </li>

                  <div>
                    <h3 style={{ textAlign: 'center' }}>Figura 4</h3>
                    <p style={{ textAlign: 'center' }}>
                      Adjetivos que descrevem os princípios da organização
                      <br />
                      <span style={{ fontStyle: 'italic' }}>
                        Por favor liste até cinco adjetivos que melhor
                        representem os princípios e valores que guiam sua
                        organização
                      </span>
                    </p>
                  </div>
                  <figure style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src="https://images.ctfassets.net/fhwlk5jsnns3/2QjBoGQA2EcVd8dzOCpWkV/2c72e8025545f2c7ba36dbc1048eaaf7/wordMap.png"
                      alt="Placeholder"
                      width="650"
                      height="650"
                    />
                  </figure>
                  <li>
                    <span className="strong">
                      MUDANÇAS IMEDIATAS E SISTÊMICAS:{' '}
                    </span>
                    Programas particularmente interessantes são ao mesmo tempo
                    sistêmicos e individualistas. Ou seja, são capazes de
                    oferecer serviços diretos a indivíduos aqui e agora enquanto
                    defendem e articulam mudanças sistêmicas que en última
                    instância vão tornar seu trabalho desnecessário. Programas
                    fazem essa combinação de diferentes maneiras. African
                    Prisons Project (Quênia, Uganda) fornece cursos e
                    certificados em direito, mas seu trabalho resultou na
                    revogação da pena de morte obrigatória nos dois países,
                    graças ao ativismo de seus estudantes; ao incluir agentes
                    penitenciários nas classes juntamente com estudantes
                    encarcerados, conseguiram uma modificação fundamental na
                    cultura das prisões, potencialmente preparando o terreno
                    para futuras mudanças sistêmicas. Juvenile Justice Advocates
                    International e Equis (México) são dois programas que
                    pressionam por mudanças das políticas públicas mas também
                    desenvolvem projetos de serviço direto, que não só lhes
                    permitem auxiliar clientes como atrair voluntários e
                    colocá-los em contato com as prisões e os encarcerados — o
                    que contribui para a transformação da cultura. Genepi
                    (França) coloca estudantes universitários de todo o país
                    para dar oficinas dentro das prisões, um serviço para os
                    encarcerados mas também uma forma de fazer com que os jovens
                    tenham um contato humanizado com detentos. Assim espera
                    despertar neles a vontade de justiça — mais uma maneira de
                    provocar mudanças culturais de longo alcance.
                    <br />
                    Curiosamente, apenas oito dos programas na Exploração
                    Profunda declararam que sua visão de longo prazo de mudança
                    mais transformativa era o “sistema prisional ser
                    desmantelado ou abolido”; sendo a resposta mais citada
                    “populações encarceradas serem drasticamente reduzidas” (21
                    programas){' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (veja a Tabela 4)
                    </a>
                    .
                  </li>
                  <li>
                    <span className="strong">O PODER DAS PESSOAS: </span>
                    Ainda que haja “receitas” para um bom trabalho, precisamos
                    salientar que sistemas e estruturas definem a justiça — mas
                    pessoas dão vida aos sistemas. Uma pessoa pode ter um
                    impacto enorme em todo um sistema judiciário — a INN
                    observou isso acontecendo da Costa Rica à República Tcheca à
                    Itália, aos Países Baixos e México, onde alternativas
                    inovadoras para prisões tradicionais (nos primeiros quatro
                    países) e mudanças significativas na prisão provisória (no
                    México) aconteceram porque um agente da justiça insistiu que
                    acontecesse, mesmo contra todos os obstáculos; a moderna
                    teoria de rede sugere haver um efeito em cascata de uma
                    única ação e uma única pessoa. O conceito “quem funciona,
                    não o que funciona” serve para os programas eficazes. Os
                    parceiros da INN enfatizaram mais de uma vez a importância
                    de uma equipe forte, especialmente — como no caso do Centro
                    de Detenção em Pequena Escala de Amsterdam Oeste, onde seis
                    dos sete funcionários são marroquinos ou surinameses —
                    funcionários da mesma cultura e comunidades que seus
                    clientes.
                  </li>
                  <li>
                    <span className="strong">
                      A FORMAÇÃO DE LÍDERES DIRETAMENTE IMPACTADOS:{' '}
                    </span>
                    O investimento em mudanças sistêmicas significa formar
                    líderes nas esferas de trabalho social e ativismo — em
                    especial líderes culturalmente diversos e diretamente
                    impactados, que tenham conhecimento em primeira mão dos
                    problemas e soluções e sejam um repositório de sabedoria,
                    não, como tantas vezes são vistos, uma fonte de problemas.
                    Permitir que pessoas com experiência direta guiem os
                    trabalhos dos programas é essencial, porém menos de 15% dos
                    funcionários dos programas na Exploração Profunda são ou
                    foram encarcerados. 30% dos programas na Exploração Profunda
                    relataram ter ao menos um funcionário com passagem no
                    sistema prisional e 12% têm um encarcerado ou egresso na
                    diretoria. Ainda que muitos programas tenham familiares de
                    encarcerados como funcionários ou voluntários, a INN espera
                    que as organizações se movimentem na direção de incluir mais
                    entre sua equipe e direção.
                    <br />
                    Para facilitar que isso ocorra, os governos precisam parar
                    de criar barreiras legislativas para que, nas palavras de
                    JustLeadership EUA, “aqueles mais próximos do problema”
                    fiquem “mais próximos da solução”. Há profissões inteiras —
                    trabalho social na África do Sul e Canadá, a área jurídica
                    no Quênia, por exemplo — que rejeitam pessoas que foram
                    condenadas, e que têm um vasto potencial de serviços
                    significativos nesses campos e em suas comunidades. Litígios
                    estratégicos fazem-se necessários em muitos países.
                  </li>
                  <li>
                    <span className="strong">
                      AMPLIAÇÃO DE “DIRETAMENTE IMPACTADO”:{' '}
                    </span>
                    Quando falamos de pessoas impactadas pelo sistema de justiça
                    criminal, é preciso ampliar a definição: precisamos
                    considerar não apenas aqueles que passam pelas prisões como
                    seus familiares, as pessoas de comunidades
                    super-representadas no encarceramento que conseguem escapar
                    das teias da justiça criminal e os bairros de onde elas vêm
                    — bairros super-representados no sistema penal como um todo.
                    Serviços e atenção para as famílias dos encarcerados têm
                    aumentado em todo o mundo, da Europa a Singapura aos EUA,
                    mas serviços para bairros e comunidades são escassos. Uma
                    exceção é o Red Hook Community Justice Center (EUA), cujos
                    serviços robustos — legais, educacionais e culturais — são
                    oferecidos para todos os membros da comunidade Red Hook, no
                    Brooklyn, e não apenas para as pessoas que entraram em
                    conflito com a lei
                  </li>

                  <li>
                    <span className="strong">LIDANDO COM TRAUMA: </span>
                    Não é possível trabalhar com justiça sem falar do elefante
                    na loja de louça: o trauma. As populações encarceradas estão
                    imersas em trauma, tanto de antes da prisão (como resultado
                    da desigualdade sistêmica e do racismo), durante os anos
                    desumanizadores no cárcere, quanto na soltura, ao enfrentar
                    a reintegração social e a estigmatização. “A essência do
                    trauma é a desconexão de nós mesmos” define o médico Gabor
                    Mate; o cárcere é o máximo da desconexão, em muitos
                    sentidos. As intervenções, portanto, precisam abarcar
                    práticas que levem em conta o trauma. Esse deve ser o foco
                    em especial de programas de reabilitação e reentrada; o
                    desenvolvimento de habilidades vocacionais, a educação e
                    empregos são vitais mas podem ser sabotados se o trauma da
                    pessoa não for reconhecido e curado.
                  </li>
                  <li>
                    <span className="strong">INOVAÇÕES RADICAIS: </span>
                    Em termos gerais, a justiça — um dos pilares fundamentais de
                    qualquer sociedade estável — merece ter liberdade e em
                    especial recursos para conduzir experiências e inovações
                    radicais. Se o Vale do Silício pode gastar para experimentar
                    aplicativos e tecnologias até encontrar o que funciona, por
                    que os trabalhadores da justiça não poderiam fazer o mesmo?
                    Todos nós merecemos um mundo em que a imaginação radical
                    seja valorizada e incentivada em nome do bem maior.
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default BlueprintPortTemplate;
